import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Welcome to Refresh",
  "subtitle": "PC Building Tips and Advice",
  "date": "2020-11-04",
  "slug": "welcome",
  "featuredImage": "./images/refresh-logo.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Computer Parts and Guides - "Refresh" educates you on how to refresh your computer with new parts. It's a blog with hand written content that walks users through how to build PCs and acquire high-demand (newest) parts for their PC builds.`}</p>
    <h2>{`Which products will be promoted?`}</h2>
    <p>{`The blog will recommend the latest and best PC products for their new PC hobby builds. We intend to promote consumer electronics PC parts.`}</p>
    <h2>{`Who is it for?`}</h2>
    <p>{`This is for hobbyists, professionals, and consumers of all age groups to learn how to build computers and to seek help on how to acquire the latest computer hardware products.`}</p>
    <h2>{`What can users do?`}</h2>
    <p>{`Users can read about how to build a PC with the latest parts and also see other PC builds that are posted from the community. It's a great resource for people researching which new PC parts to buy and how to put the latest parts together.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      